@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

.header_row_layout {
  box-sizing: border-box;
  display: table-cell;
  height: 39px;
  margin: 0px;
  padding: 8px;
  width: 100%;
}

.header_row_text {
  font-family: 'Mont Regular', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: left;
  text-indent: 0px;
  text-size-adjust: 100%;
  text-transform: none;
  vertical-align: middle;
}

.header_row_appearance {
  @extend .baseborder;

  background-color: #d1e5fb;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-style: none;
  border-left-width: 0px;
  border-right-style: none;
  border-right-width: 0px;
  border-top-style: none;
  border-top-width: 0px;
}

.header_row_table {
  border-collapse: separate;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
}

.header_row_other {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  overflow-wrap: break-word;
}

.header_text_layout {
  box-sizing: border-box;
  display: inline;
  height: auto;
  margin: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 3.75px;
  padding-top: 0px;
  width: auto;
}

.header_text_text {
  font-family: 'Mont Regular', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: left;
  text-indent: 0px;
  text-size-adjust: 100%;
  text-transform: none;
}

.header_text_appearance {
  color: $text-color;
  border-bottom-color: $text-color;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: $text-color;
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: $text-color;
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: $text-color;
  border-top-style: none;
  border-top-width: 0px;
}

.header_text_table {
  border-collapse: separate;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
}

.header_text_other {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  overflow-wrap: break-word;
}

// override

// .ag-header-row {
//   @extend .header_row_layout;
//   @extend .header_row_text;
//   @extend .header_row_appearance;
//   @extend .header_row_table;
//   @extend .header_row_other;
// }

// .ag-header-cell-label {
//   @extend .header_text_layout;
//   @extend .header_text_text;
//   @extend .header_text_appearance;
//   @extend .header_text_table;
//   @extend .header_text_other;
// }
