.field_label_layout {
  @extend .baselayout;
  clear: both;
  height: 18px;
  padding: 0px;
}

.field_label_appearance {
  @extend .baseborder;

  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-style: none;
  border-left-width: 0px;
  border-right-style: none;
  border-right-width: 0px;
  border-top-style: none;
  border-top-width: 0px;
  cursor: default;
}

.field_label_other {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  overflow-wrap: break-word;
}

label.field {
  @extend .basetext;
  @extend .field_label_layout;
  @extend .field_label_appearance;
  @extend .field_label_other;
}
