div.description {
  display: block;
  font-family: $font-family;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 19.5px;
  overflow-wrap: break-word;
  text-align: left;
  text-indent: 0px;
  text-size-adjust: 100%;
}

.error {
  color: #ff0000;
}
