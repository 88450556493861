.input_layout {
  @extend .baselayout;
  max-width: 100%;
  padding: 10px;
}

.input_text {
  @extend .basetext;

  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  letter-spacing: normal;
  line-height: 21px;
  text-shadow: none;
  vertical-align: baseline;
  word-spacing: 0px;
}

.input_appearance {
  @extend .baseborder;

  background-attachment: scroll;
  background-clip: border-box;
  background-color: $background-color;
  background-image: none;
  background-origin: padding-box;
  background-size: auto;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 1px;
  cursor: text;
  -webkit-border-image: none;
}

.input_animation {
  transition-delay: 0s, 0s;
  transition-duration: 0.1s, 0.1s;
  transition-property: color, background-color;
  transition-timing-function: ease-in-out, ease-in-out;
}

.input_other {
  text-rendering: auto;
  writing-mode: horizontal-tb;
  appearance: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  overflow-wrap: break-word;
  -webkit-rtl-ordering: logical;
}

input {
  @extend .input_layout;
  @extend .input_text;
  @extend .input_appearance;
  @extend .input_animation;
  @extend .input_other;
}
