$background-color: #fafafa;
$border-color: #a6b4dd;
$text-color: #162240;
$button-primary: #0029a9;
$font-family: 'Mont Bold', Sans-serif;

body {
  margin: 0;
}

// Base Text Class
.basetext {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  text-indent: 0px;
  text-size-adjust: 100%;
  text-transform: none;
}

// Base Layout Class
.baselayout {
  box-sizing: border-box;
  display: inline-block;
  margin: 0px;
}

// Base Border Class
.baseborder {
  color: $text-color;
  border-bottom-color: $border-color;
  border-left-color: $border-color;
  border-right-color: $border-color;
  border-top-color: $border-color;
}

// Box Gap Spacing
.MuiBox-root + .MuiBox-root {
  padding-bottom: 5px;
}

.MuiGrid-container {
  padding: 10px;
}

.uppercase {
  text-transform: uppercase;
}
