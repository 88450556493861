input[type='button'],
html input[type='button'],
input[type='reset'],
input[type='submit'],
.button {
  margin: 5px;
  font-family: $font-family;
  font-weight: normal;
  border: 0;
  outline: 0;
  font-size: 15px;
  background-color: $button-primary;
  display: inline-block;
  padding: 6px 16px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  -webkit-appearance: button;
  text-align: center;
  width: max-content;
}
