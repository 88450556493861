@import 'assets/styles/base';

@import 'assets/styles/forms/button';
@import 'assets/styles/forms/div';
@import 'assets/styles/forms/input';
@import 'assets/styles/forms/labels';
@import 'assets/styles/forms/radio';
@import 'assets/styles/forms/table';

// Mont-Regular
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Regular.ttf');
  font-weight: 400;
}

// Mont-Bold
@font-face {
  font-family: 'Mont';
  font-weight: 500;
  src: url('./fonts/mont-bold-normal_7775d261dbb3c45d7d0b980e0cb93cc2.ttf');
}

// Mont-Heavy
@font-face {
  font-family: 'Mont';
  font-weight: 700;
  src: url('./fonts/mont-heavy-normal_80875ce1510172b05ef5f40855f20a19.ttf');
}
